import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer, EuiButton } from '@elastic/eui';
import { Link } from 'gatsby';
import IntroTaskerCards from 'components/Advanced_User/Tasker/IntroTasker';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Tasker Android App",
  "path": "/Advanced_User/Tasker/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Use a macro tool to automate functions of your camera",
  "image": "./AU_SearchThumb_Tasker.png",
  "social": "/images/Search/AU_SearchThumb_Tasker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Tasker_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Tasker Android App' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use a macro tool to automate functions of your camera' image='/images/Search/AU_SearchThumb_Tasker.png' twitter='/images/Search/AU_SearchThumb_Tasker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Tasker/' locationFR='/fr/Advanced_User/Tasker/' crumbLabel="Tasker" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "tasker-android-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#tasker-android-app",
        "aria-label": "tasker android app permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tasker Android App`}</h1>
    <h2 {...{
      "id": "use-cgi-commands-to-automate-functions-of-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-cgi-commands-to-automate-functions-of-your-camera",
        "aria-label": "use cgi commands to automate functions of your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use CGI commands to automate functions of your camera`}</h2>
    <h3 {...{
      "id": "tasker--android-macro-tool",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tasker--android-macro-tool",
        "aria-label": "tasker  android macro tool permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tasker :: Android Macro Tool`}</h3>
    <p>{`To automate your IP camera with your Android phone you need a macro tool, like the app Tasker. The current (paid) version can be found on the Google Play Store. Just enter the search term Tasker in the store to be directed to the app.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <EuiButton color="primary" fill href="https://play.google.com/store/apps/details?id=net.dinglisch.android.taskerm&hl=en" target="_blank" rel="noopener noreferrer" mdxType="EuiButton">Download</EuiButton>
    <EuiSpacer mdxType="EuiSpacer" />
    <h4 {...{
      "id": "case-1-activate-the-motion-detection-when-you-are-in-the-house",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#case-1-activate-the-motion-detection-when-you-are-in-the-house",
        "aria-label": "case 1 activate the motion detection when you are in the house permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Case 1: Activate the motion detection when you are in the house`}</h4>
    <p>{`Do you use your camera as a peephole and always want to be alerted immediately when someone approaches the door? But you do not want to be disturbed by the camera when you're on the move? You can automate this task with the Android App Tasker! In two examples (below), we'll create tasks that enable motion detection on your INSTAR HD camera via a profile when your smartphone is on your home network, and disable it when you leave your network. In the opposite case - you only want to focus the motion alarm when you are away from home - you simply need to use the two CGI commands in reverse order.`}</p>
    <p>{`Using the same principle, you can automate almost all camera functions - both for our HD cameras and VGA models. The required CGI commands can be found in our CGI documentation:`}</p>
    <p><strong parentName="p">{`CGI instruction sets for camera series:`}</strong></p>
    <ul>
      <li parentName="ul">
        <Link to="/1080p_Series_CGI_List/" mdxType="Link">1080p Series</Link>
      </li>
      <li parentName="ul">
        <Link to="/720p_Series_CGI_List/" mdxType="Link">720p Series</Link>
      </li>
      <li parentName="ul">
        <Link to="/Advanced_User/CGI_Commands/VGA_Serie_CGI_Befehle/" mdxType="Link">VGA Series</Link>
      </li>
    </ul>
    <h4 {...{
      "id": "case-2-switch-on-the-ir-leds-of-your-camera-at-the-push-of-a-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#case-2-switch-on-the-ir-leds-of-your-camera-at-the-push-of-a-button",
        "aria-label": "case 2 switch on the ir leds of your camera at the push of a button permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Case 2: Switch on the IR LEDs of your camera at the push of a button`}</h4>
    <p>{`Tasker allows you to create tasks as shortcuts on your home screen or to create entire user interfaces (Scenes). In the following we would like to find a quick way to activate and deactivate the IR LEDs of our INSTAR HD camera. In the first example we put shortcuts with the corresponding tasks on the homescreen of our Android smartphone. In the second case, we want to create an overlay (Scene) that provides these two functions as buttons. Of course, the resulting scene can then be extended with additional buttons and support for swipe gestures (for example for PTZ control or setting of sliders) and thus becomes a command center for your camera surveillance.`}</p>
    <h4 {...{
      "id": "case-3-day--night-switching-of-motion-detection-and-camera-position",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#case-3-day--night-switching-of-motion-detection-and-camera-position",
        "aria-label": "case 3 day  night switching of motion detection and camera position permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Case 3: Day & night switching of motion detection and camera position`}</h4>
    <p>{`During the day and at night it often makes sense to define different sensitivities for motion detection. Also you might want to monitor another area at night. We will program the necessary tasks for this and trigger you automatically via a profile at sunrise or sunset.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <IntroTaskerCards mdxType="IntroTaskerCards" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      